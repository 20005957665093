$large-font: 'Fredoka One', cursive;
$non-large-font: 'Montserrat', sans-serif;
$dark-blue: #66a99e;
$dark-green: #67a451;
$medium-green: #a5da66;
$light-green: #c6ddbe;
:root {
    --large-font: #{$large-font};
    --non-large-font: #{$non-large-font};
    --dark-blue: #{$dark-blue};
    --dark-green: #{$dark-green};
    --medium-green: #{$medium-green};
    --light-green: #{$light-green};
}
body {
    opacity: 0;
    transition: opacity 0.3s;
}
::selection {
    background: hsla(212, 100%, 83%, 0.678); /* WebKit/Blink Browsers */
}
::-moz-selection {
    background: hsla(212, 100%, 83%, 0.678); /* Gecko Browsers */
}
body {
    margin: 0px;
}
// Main background image
.bg {
    background: url(../res/bg.svg) no-repeat center center fixed;
    background-size: cover;
    position: fixed;
    display: flex;
    height: 105vh;
    width: 105vw;
    align-items: center;
    filter: blur(10px) saturate(240%);
}
#main {
    position: absolute;
    display: grid;
    height: 100%;
    width: 100%;
    justify-items: center;
    align-items: center;
}
.main-home {
    align-items: start !important;
}
.hidden {
    display: none;
}
.fatal-error {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: white;
}
