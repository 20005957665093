:root {
  --large-font: Fredoka One, cursive;
  --non-large-font: Montserrat, sans-serif;
  --dark-blue: #66a99e;
  --dark-green: #67a451;
  --medium-green: #a5da66;
  --light-green: #c6ddbe;
}

body {
  opacity: 0;
  transition: opacity .3s;
}

::-moz-selection {
  background: #a8d1ffad;
}

::selection {
  background: #a8d1ffad;
}

body {
  margin: 0;
}

.bg {
  height: 105vh;
  width: 105vw;
  filter: blur(10px) saturate(240%);
  background: url("bg.6b04a15c.svg") center / cover no-repeat fixed;
  align-items: center;
  display: flex;
  position: fixed;
}

#main {
  height: 100%;
  width: 100%;
  place-items: center;
  display: grid;
  position: absolute;
}

.main-home {
  align-items: start !important;
}

.hidden {
  display: none;
}

.fatal-error {
  width: 100%;
  height: 100%;
  background-color: #fff;
  position: absolute;
}

/*# sourceMappingURL=index.bbed5aff.css.map */
